import { ProjectIntegrationProviderName } from "@/store/integrations/integrations-selectors";
import {
  ExternalMarkupIElement,
  IElementType,
} from "@faro-lotv/ielement-types";
import { BcfServicesIntegrationType } from "@faro-lotv/service-wires";

type IntegrationsDataAssociations = {
  /** The "BCF Services" integration type */
  bcfIntegrationType: BcfServicesIntegrationType;
  /** The iElementType corresponding to the "BCF Services" integration type */
  iElementType: ExternalMarkupIElement["type"];
  /** The name of the project integration provider */
  providerName: ProjectIntegrationProviderName;
};

// The associations between "BCF Services" integration types and the other related data adn types defined elsewhere
const integrationsDataAssociations: IntegrationsDataAssociations[] = [
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskAccIssues,
    iElementType: IElementType.markupAccIssue,
    providerName: ProjectIntegrationProviderName.autodeskAcc,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskAccRfis,
    iElementType: IElementType.markupAccRfi,
    providerName: ProjectIntegrationProviderName.autodeskAcc,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskBim360Issues,
    iElementType: IElementType.markupBim360,
    providerName: ProjectIntegrationProviderName.autodeskBim360,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.procoreObservations,
    iElementType: IElementType.markupProcoreObservation,
    providerName: ProjectIntegrationProviderName.procore,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.procoreRfis,
    iElementType: IElementType.markupProcoreRfi,
    providerName: ProjectIntegrationProviderName.procore,
  },
];

/**
 * @param providerName The name of the integration provider
 * @returns all "BCF Services" integration types for the given project integration provider
 */
export function getAllBcfServicesIntegrationTypes(
  providerName: ProjectIntegrationProviderName,
): BcfServicesIntegrationType[] {
  return integrationsDataAssociations
    .filter((association) => association.providerName === providerName)
    .map((association) => association.bcfIntegrationType);
}

import { UploadedFile } from "@/components/common/file-upload-context/use-file-uploader";
import { Tag } from "@/store/tags/tags-slice";
import { FileDetails } from "@faro-lotv/flat-ui";
import { BcfServicesIntegrationType } from "@faro-lotv/service-wires";

/** The detailed information about an Annotation */
export type AnnotationProps = {
  /** The annotation title */
  title: string;

  /** The annotation description */
  description?: string;

  /** The user assigned to this Annotation */
  assignee?: string;

  /** The current status of this Annotation */
  status?: string;

  /** The due date for the Annotation */
  dueDate?: Date;

  /** The labels marking the annotation */
  tags?: Tag[];
};

/** The type of a new attachment for the annotation */
export type NewAttachment = UploadedFile & FileDetails;

/** Defines the props required for the creation of a Sphere XG annotation */
export type SphereXGAnnotationData = AnnotationProps & {
  /** The list of new attachments for this annotation */
  newAttachments: NewAttachment[];
};

/**
 * Defines the fields that are specific to external annotations. For fields description, see the BCF API documentation at:
 * https://github.com/buildingSMART/BCF-API/?tab=readme-ov-file#322-post-topic-service
 */
export type ExternalAnnotationData = Omit<AnnotationProps, "tags"> & {
  /** The external annotation type that should be created */
  externalAnnotationType: BcfServicesIntegrationType;

  /** The priority of a topic */
  priority?: string;

  /** The type of a topic */
  topicType?: string;

  /** Stage this topic is part of */
  stage?: string;
};

/**
 * Type guard function to check if the given data is of type `ExternalAnnotationProps`.
 *
 * @param data - The data to check, which can be either `AnnotationWithNewAttachments` or `ExternalAnnotationProps`.
 * @returns A boolean indicating whether the data is of type `ExternalAnnotationProps`.
 */
export function isExternalAnnotationData(
  data: SphereXGAnnotationData | ExternalAnnotationData,
): data is ExternalAnnotationData {
  return "externalAnnotationType" in data;
}

/** Defines a type containing data required for the creation of an annotation */
export type AnnotationCreationData =
  | SphereXGAnnotationData
  | ExternalAnnotationData;
